import React from 'react';
import styled from 'styled-components';
import data from './FaqData';
import * as Icon from '../../components/Icon';
import {BREAK_POINTS} from '../../domain/Constants';
import qs from 'query-string';

function FaqPage(props) {
  const {location} = props;
  const {p = ''} = qs.parse(location.search);
  const getInitPage = () => {
    if (!p) {
      return 0;
    } else {
      try {
        return {
          questions: 0,
          'privacy-policy': 1,
          'terms-of-use': 2,
        }[p];
      } catch (e) {
        return 0;
      }
    }
  };
  const [activeIdx, setActiveIdx] = React.useState(getInitPage);

  return (
    <Wrapper>
      <div className="container">
        <div className="list">
          {data.map((item, idx) => (
            <div
              className={`item${activeIdx === idx ? ' active' : ''}`}
              key={idx}
              onClick={() => setActiveIdx(idx)}>
              <span>{item.label}</span>
              <Icon.KeyboardArrowRight size={20} color={'#595757'} />
            </div>
          ))}
        </div>
        <div className="content">
          <h2>{data[activeIdx].label}</h2>
          {data[activeIdx].items.map((item, idx) => (
            <div className="group" key={idx}>
              {item.title && <div className="title">◆ {item.title}</div>}
              <p>{item.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    padding: 55px var(--content-padding);
    max-width: var(--content-max-width);
    margin: 0 auto;

    display: flex;
    & > .list {
      flex-basis: 240px;
      margin-right: 52px;

      & > .item {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #e5e5ea;
        margin-bottom: 10px;
        padding: 12px;
        font-size: 14px;
        color: #595757;
        font-family: 'Noto Sans TC';
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & > .item.active {
      }
    }

    & > .content {
      flex: 1;
      & > h2 {
        font-size: 20px;
        padding: 10px;
        background-color: var(--theme-primary);
        color: #fff;
        letter-spacing: 0;
      }
      & > .group {
        font-size: 14px;
        color: #595757;
        letter-spacing: 0;
        padding: 20px;
        & > .title {
        }
        & > p {
          white-space: pre-line;
        }
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .container {
      flex-direction: column;
      & > .list {
        flex-basis: auto;
        margin-right: 0px;
      }

      & > .content {
        & > .group {
          padding: 10px 0px;
        }
      }
    }
  }
`;

export default FaqPage;
