const data = [
  {
    key: 'faq',
    label: '常見問題',
    items: [
      {
        title: '',
        answer: `
        ▍ 若發票資料有誤，如：忘記開立統編、統編打錯、金額錯誤，怎麼處理？
>> 收到發票七日內（含假日）若資料有誤，需更改抬頭名稱或統一編號，請您至網站右下方點選【LINE客服】註明要修改的抬頭、統編及發票寄送EMAIL地址，客服人員將會盡速為您處理（逾期恕不受理）

▍ 二聯式發票可以改為三聯式發票嗎？
>> 請於下單選擇時確認二聯電子發票(個人消費)或三聯(公司報帳用)發票，為配合國稅局勸止二聯換開三聯之政策，本公司有權利考量各因素後拒絕換開發票。


▍ 已開立電子發票還可以換為實體發票嗎？
>> 無法為您更換為實體發票。
官網消費一律為電子發票，會於結帳後EMAIL至您的信箱
若需實體發票，則限定於門市現場消費。


▍ 若商品有狀況出現，怎麼處理？
>> 如有非人為因素所致之商品損毀、漏寄、或商品出現狀況，請務必當場錄影存證、保留完整商品，並於第一時間致電或私訊告知客服人員並留下訂購人資訊，我們將儘快為您處理後續事宜。


▍ 產品保存期限？
>> 法朋嚴選新鮮食材，皆無添加防腐劑，故賞味期限較短為正常現象。
慶祝蛋糕以及新鮮水果類甜點，建議當天（最晚隔天）一定要食用完畢。
其餘商品依照商品出貨標籤上日期為準。
法朋會隨貨附上保冷劑維持新鮮，蛋糕請於二十分鐘內進冷藏保存。


▍ 原本訂單的自取時段無法取貨，該怎麼聯絡通知法朋?
>> 若您的取貨日為同一天，僅延遲取貨時段，法朋將為您保留商品至訂單自取日19:00，無需特別通知，但為保商品新鮮及品質，請您盡量於原定取貨時段前來取貨。


▍ 預訂天數不足，買得到蛋糕嗎？
>> 門市每天販售少量的生鮮奶油蛋糕，建議您可於營業內(11:30-19:30)直接至現場選購。若想要指定蛋糕，請提前3-5天預訂，現場購買會有撲空或售罄的可能性。


▍ 為什麼有些蛋糕無法宅配？
>> 我們的宅配透過黑貓宅急便寄出，運送過程中容易毀損，
故無法寄送鮮奶油蛋糕或造型蛋糕。


▍ 如何自取蛋糕？
>> 取貨日當天請至門市右手邊的【 僅取貨區 】 排隊。
提供【 訂購人 】的姓名與手機號碼向門市確認訂單就可以取到蛋糕。


▍ 已付款但臨時想取消訂單（或改期）
>> 接到您的訂單師傅才會備料製作，法朋希望讓您享用到最新鮮的甜點，因此您的變更都會造成食材報廢及損壞。

>> 已確認訂單恕不接受取消，欲改期請至少於【 取貨日前四天 】來電或告知客服，且只能延後無法提前。若不配合因而造成您的損失請自行負責。
(例如您於8/8下單8/16要自取蛋糕，則您最晚需要於8/12告知需要延期到何日取貨)


▍ 為什麼我選自取預訂，抵達門市還需要排隊
>> 由於疫情門市會限制進入門市人數，加上熱門取貨時段(11:30-13:00)均有可能造成排隊的狀況，謝謝您的諒解。


▍ 可以指定到貨日期與時段嗎？
>> 我們宅配與黑貓宅急便配合運送，無法指定到貨日期與時段。

>> 若您居住雙北區域，有指定到貨時段需求，建議您可至我們官方合作外送平台卡個位下單：https://reurl.cc/GGK93
注意事項 ①卡個位外送平台會另外酌收手續費、網站維護費、管家外送費等等，建議先至網站進行金額試算再進行下單 ②下單後若要修改訂單等任何問題（因個資法法朋無權限瀏覽或更改訂單）請直接跟卡個位客服聯絡https://reurl.cc/GGK93


▍ 為什麼宅配沒辦法指定到貨日與時段？
>> 黑貓宅急便週日不收、送貨。
>> 黑貓宅急便送貨量過多時，無法指定到貨日，有可能延遲二到三日到貨。
>> 疫情影響，可能會延遲2-3天到貨，也無法指定配送時間，亦不會當日寄出，當日就收到貨，寄出後送達日期及時間由黑貓宅配安排。
例如：宅配到貨日選擇9/7，法朋將會安排貨物於9/6寄出，但可能9/8或9/9才會到貨。


▍ 提醒您，疫情期間及如遇春節、母親節、端午節、中秋節與聖誕節，運送繁盛時期，可指定日期但無法承諾於指定日期送達；造成不便，敬請見諒。

▍ 如訂購商品有多點配送之需求，請您個別下單。單一訂購僅接受單一配送地址。

▍ 建議您先行聯繫收件人，告知商品配送日期與時間；若配送無法順利送達，遇配送公司退回，恕無法辦理退貨與退款。則如需重新寄送或改寄其他配送地址，將酌收配送費用。

▍ 法朋將委由第三方配送公司為您提供服務，如：Lalamove、計程車或黑貓宅急便等，恕法朋無法承擔運送風險。如有疑慮，建議您選擇「自取」，以避免爭議。造成不便，敬請見諒。

▍ 若因天災或意外等人為不可抗力之因素，而無法按時配送寄送，將通知客戶延後送貨或為您取消訂單。
        `,
      },
    ],
  },
  {
    key: 'policy',
    label: '隱私權政策',
    items: [
      {
        title: '',
        answer: `
      非常歡迎您光臨「法朋烘焙坊官方網站」（以下簡稱本網站），為了讓您能夠安心使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：

一、隱私權保護政策的適用範圍
隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。

二、個人資料的蒐集、處理及利用方式
當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
行使用人資料權利方式：依個人資料保護法第3條規定，您就您的個人資料享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利。您可書面郵寄至【106台北市大安區仁愛路四段300巷20弄11號】方式行使上述權利，本公司將於收悉您的請求後，儘速處理。

三、資料之保護
本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。

四、網站對外的相關連結
本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。

五、與第三人共用個人資料之政策
本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。

前項但書之情形包括不限於：

經由您書面同意。
法律明文規定。
為免除您生命、身體、自由或財產上之危險。
與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
有利於您的權益。
本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。

六、Cookie之使用
為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。

七、隱私權保護政策之修正
本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
      `,
      },
    ],
  },
  {
    key: 'user-policy',
    label: '使用者條款',
    items: [
      {
        title: '',
        answer: `
      服務條款與購買說明

一. 訂購與訂單查詢注意事項

1.訂購流程：
1.瀏覽您喜歡的商品，進入商品頁面
2.選擇訂購規格
3.選擇訂購數量
4.選擇取貨方式：因應商品特性，每個商品開放的取貨方式不一定相同，請點開下拉選單選擇
5.選擇日期：
*自取為實際前往法朋門市取貨的日期與時間，同一筆訂單的日期與時間必須ㄧ致，若選擇不同時間，將以第一個加入購物車的商品選擇的時間作為實際取貨時間。
*宅配為希望到貨的時間（收到黑貓物流交貨的時間），為避免貨運量大延遲風險，建議提前訂購或使用自取方式購買商品。
6.選擇時段
*宅配無法指定到貨時間，只能選擇希望到貨的時段。
7.立即購買或加入購物車：可點選立即購買直接進入購物車頁面，或點加入購物車繼續選購。
*法朋烘焙坊商品皆為新鮮製作，訂單「完成付款」後才算成立，方正式進入生產製作流程。

2.提醒您，在購物車按下「下一步」前，請再次確認商品口味、數量、取貨日/配送日、金額是否正確。

3.如需查詢訂單狀態，請至[會員中心]的[訂單總覽」查詢訂單。

4.前往結帳後，請儘快完成信用卡付款，15分鐘後付款頁面將會失效。

因商品流動速度快，若有確定訂購的商品，加入購物車後建議後先進行結帳，若系統偵測到購物車內商品已不足供應您購物車的數量，本商品將會自動被清空。
本站不進行商品預留，訂單成立順序以付款先後順序為準，若遇結帳時商品庫存不足導致無法購買，敬請見諒。
5.到貨日期會依照您指定選取的日期安排配送，但當日送達的時間仍以物流司機實際安排為主，恕無法指定與保證準點配達。遇特殊節慶時，配送易有延遲或早到狀況，也可能增加商品受損的機率， 為保商品品質無虞，建議您提前一至二天預訂或改以法朋門市自取，以免耽誤您的重要節日。

6.法朋烘焙坊保有修改商品、暫停接單、取消訂單之權利。

7.本站為響應環保，使用線上電子發票，不隨貨附發票。發票開立後，將會寄送email至會員收件人email地址。您也可至[訂單總覽]內進行查詢發票開立狀態。​

8.若需要發票三聯發票寄送服務，請在結帳填寫收件人資訊頁面，勾選三聯發票。若發票寄送地址與會員地址不同，請填寫發票寄送地址。

二. 配送服務相關注意事項

1.若於運送過程中，因路面環境導致蛋糕位移、變形或盒內四周沾到奶油、裝飾品，或水果、巧克力裝飾片脫落等情況，恕不接受退貨或退款。若您無法承受運送風險，建議改選法朋烘焙坊門市自取。

本站提供取貨方式為：法朋烘焙坊門市自取/黑貓常溫宅配/黑貓冷藏宅配/黑貓冷凍宅配。
目前不提供離島宅配服務，花東地區若選擇宅配，會加收10元費用。
3.因貨運量龐大或不可抗力之因素，到貨時間可能會延遲，黑貓宅急便無法確保包裹隔日送達。可能會延遲2-3天到貨（也無法指定配送時間，建議提前1-2天訂購寄出蛋糕。以免延誤到您的重要日子）

4.寄件日不是到貨日
寄出後送達日期以及時間由黑貓宅配安排(週日不收、送貨)。需要2-3天時間才會收到 (例如:您禮拜一寄出，可能禮拜三、四才會收到，不會禮拜一就到貨！)

商品出貨後，您可於[會員中心]的[訂單總覽]中查詢物流編號，進度可由黑貓包裹查詢頁面查詢：https://www.t-cat.com.tw/inquire/trace.aspx。
三. 退換貨說明

1.依據「通訊交易解除權合理例外情事適用準則」第2條第一項：「易於腐敗、保存期限較短或解約時即將逾期」之商品，屬於消費者保護法第19條第一項但書所稱合理例外情事。自105年1月1日起，生鮮食品不適用於消費者保護法第19條，並不享有7天猶豫期。

2.黑貓貨運量龐大，蛋糕或商品可能發生不可避免之碰撞或位移，若無法接受請勿選擇宅配，建議使用自取方式領取。

3.若有任何疑問，請洽本站一對一LINE客服。

防詐騙提醒：
※法朋烘焙坊不會以電話主動要求您操作ATM進行轉帳或提供任何資料，請勿理會+號開頭可疑電話，因來電顯示
可經變造，如接獲類似電話或簡訊請勿理會，建議撥打『165』反詐騙專線，或通知法朋烘焙坊協助處理，謝謝
      `,
      },
    ],
  },
];

export default data;
